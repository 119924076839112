/* HomeBoard.css */
/* @import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap"); */

.homeboard {
	/* overflow: hidden; */
	height: 93vh;
}

.divider {
	background-color: rgb(186, 186, 186);
}

h1 {
	padding-top: 30vh;
}

p {
	font-size: 17px;
}
