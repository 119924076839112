/* .navbar-light .navbar-brand {
	color: white;
} */

a {
	color: white !important;
	text-decoration: none !important;
}

.Logo {
	font-size: 30px !important;
}

a .Dropdown {
	color: rgb(22, 21, 18, 1) !important;
}

button {
	text-decoration: none;
	border: none;
	background-color: white;
	padding: 0px 0px;
	background-color: transparent;
}

.Dropdown {
	padding-right: 70px;
	padding-top: 8px;
	padding-bottom: 8px;
}

.navbar {
	max-height: 7vh;
}
