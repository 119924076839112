/* PostCard.css */

.card {
	width: 50vh;
}

.card-body {
	background: rgb(32, 30, 27);
	background: radial-gradient(
		circle,
		rgba(40, 37, 35, 1) 90%,
		rgba(41, 40, 35, 1) 100%
	);
	border: transparent;
}

.postCardImage {
	width: 100%;
}

h6 {
	display: flex;
	font-size: 10px;
	align-items: baseline;
}

.buttonCard {
	background-color: transparent !important;
	border-color: transparent !important;
}

h6 {
	color: white;
}

.card-floor {
	display: flex;
	justify-content: space-between;
}

.btn:focus {
	outline: none;
	box-shadow: none;
}

.cardBox {
	display: flex;
	justify-content: center;
	padding-bottom: 20px;
}

.bi {
	padding-right: 5px;
}

.fa-icon {
	color: white !important;
}
.fa-icon:hover {
	color: red !important;
}

.supraflex {
	display: flex;
	justify-content: space-between;
}

.rightflex {
	display: flex;
	align-items: baseline;
}
