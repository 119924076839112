@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap);
.App {
	text-align: center;
	background: rgb(43, 40, 35);
	background: linear-gradient(
		165deg,
		rgba(43, 40, 35, 1) 47%,
		rgba(22, 21, 18, 1) 100%
	);
	font-family: "Ubuntu", sans-serif;
	/* height: 90vh; */
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* .navbar-light .navbar-brand {
	color: white;
} */

a {
	color: white !important;
	text-decoration: none !important;
}

.Logo {
	font-size: 30px !important;
}

a .Dropdown {
	color: rgb(22, 21, 18, 1) !important;
}

button {
	text-decoration: none;
	border: none;
	background-color: white;
	padding: 0px 0px;
	background-color: transparent;
}

.Dropdown {
	padding-right: 70px;
	padding-top: 8px;
	padding-bottom: 8px;
}

.navbar {
	max-height: 7vh;
}

/* HomeBoard.css */
/* @import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap"); */

.homeboard {
	/* overflow: hidden; */
	height: 93vh;
}

.divider {
	background-color: rgb(186, 186, 186);
}

h1 {
	padding-top: 30vh;
}

p {
	font-size: 17px;
}

/* Form.css */

.form {
	border: solid 1px white;

	padding: 20px;
	border-radius: 8px;
	max-width: 400px;
	margin: 40px auto;
}

.form input {
	margin: 20px 0;
}

/* background-image: linear-gradient(270deg, #3c3b3f, #605c3c); */
.form button {
	border-color: transparent;
	font-weight: bold;
	background: linear-gradient(
		91deg,
		#333333,
		#dd1818,
		#ee0979,
		#ff6a00,
		#f12711,
		#f5af19,
		#636363,
		#a2ab58,
		#56ab2f,
		#a8e063,
		#f1f2b5,
		#00f260,
		#0575e6,
		#40e0d0,
		#ff8c00,
		#ff0080,
		#1a2a6c,
		#b21f1f,
		#ff0080,
		#2c3e50,
		#fd746c,
		#12c2e9,
		#c471ed,
		#f64f59,
		#e6dada,
		#274046,
		#1f1c2c,
		#928dab
	);
	background-size: 5600% 5600%;

	animation: AnimationName 60s ease infinite;
}

h2 {
	color: white;
}

@keyframes AnimationName {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 51%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.container-form {
	overflow: hidden;
	height: 88.1vh;
}

/* PostCard.css */

.card {
	width: 50vh;
}

.card-body {
	background: rgb(32, 30, 27);
	background: radial-gradient(
		circle,
		rgba(40, 37, 35, 1) 90%,
		rgba(41, 40, 35, 1) 100%
	);
	border: transparent;
}

.postCardImage {
	width: 100%;
}

h6 {
	display: flex;
	font-size: 10px;
	align-items: baseline;
}

.buttonCard {
	background-color: transparent !important;
	border-color: transparent !important;
}

h6 {
	color: white;
}

.card-floor {
	display: flex;
	justify-content: space-between;
}

.btn:focus {
	outline: none;
	box-shadow: none;
}

.cardBox {
	display: flex;
	justify-content: center;
	padding-bottom: 20px;
}

.bi {
	padding-right: 5px;
}

.fa-icon {
	color: white !important;
}
.fa-icon:hover {
	color: red !important;
}

.supraflex {
	display: flex;
	justify-content: space-between;
}

.rightflex {
	display: flex;
	align-items: baseline;
}

/* GalleryView.css */

html {
	width: 100%;
	height: 100%;
}

/* PostView.css */

.form-control .btn .btn-danger {
	width: 40px;
}

.container-postview {
	display: flex;
	flex-direction: column;
	align-items: center;
}

/* PostViewCard.css */

.card {
	/* background: transparent; */
	/* background-color: yellow; */

	width: 70vh;
}

.card-body {
	background: rgb(32, 30, 27);
	background: radial-gradient(
		circle,
		rgba(40, 37, 35, 1) 90%,
		rgba(41, 40, 35, 1) 100%
	);
	border: transparent;
}

.postCardImage {
	width: 100%;
}

h6 {
	display: flex;
	font-size: 10px;
}

.buttonCard {
	background-color: transparent !important;
	border-color: transparent !important;
}

h6 {
	color: white;
}

.card-floor {
	display: flex;
	justify-content: space-between;
}

.btn:focus {
	outline: none;
	box-shadow: none;
}

.cardBox {
	display: flex;
	justify-content: center;
	padding-bottom: 20px;
}

.bi {
	padding-right: 5px;
}

.fa-icon {
	color: white !important;
}
.fa-icon:hover {
	color: red !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

