@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap");

.App {
	text-align: center;
	background: rgb(43, 40, 35);
	background: linear-gradient(
		165deg,
		rgba(43, 40, 35, 1) 47%,
		rgba(22, 21, 18, 1) 100%
	);
	font-family: "Ubuntu", sans-serif;
	/* height: 90vh; */
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
