/* Form.css */

.form {
	border: solid 1px white;

	padding: 20px;
	border-radius: 8px;
	max-width: 400px;
	margin: 40px auto;
}

.form input {
	margin: 20px 0;
}

/* background-image: linear-gradient(270deg, #3c3b3f, #605c3c); */
.form button {
	border-color: transparent;
	font-weight: bold;
	background: linear-gradient(
		91deg,
		#333333,
		#dd1818,
		#ee0979,
		#ff6a00,
		#f12711,
		#f5af19,
		#636363,
		#a2ab58,
		#56ab2f,
		#a8e063,
		#f1f2b5,
		#00f260,
		#0575e6,
		#40e0d0,
		#ff8c00,
		#ff0080,
		#1a2a6c,
		#b21f1f,
		#ff0080,
		#2c3e50,
		#fd746c,
		#12c2e9,
		#c471ed,
		#f64f59,
		#e6dada,
		#274046,
		#1f1c2c,
		#928dab
	);
	background-size: 5600% 5600%;

	animation: AnimationName 60s ease infinite;
}

h2 {
	color: white;
}

@keyframes AnimationName {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 51%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.container-form {
	overflow: hidden;
	height: 88.1vh;
}
